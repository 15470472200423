@media print {
  #main-nav,
  .global-nav,
  .header-global,
  .header-global__24hours,
  .stickyicons,
  .article-info__bg-streifen,
  .btn-back,
  .article-info .slider-angle--left,
  .article-info .slider-angle--right,
  .slider-main ~ .angle-bg-left,
  .slider-main ~ .angle-bg-right,
  .slider-nav,
  .slider-nav-image,
  .btn--article-info,
  .image-sources,
  .contact_2cols-phone:before,
  .contact_2cols-mail:before,
  .footer-nav,
  .footer-meta,
  .footer__logo,
  .footer__social,
  .footer__map-xl,
  .d-xl-block {
    display: none!important;
  }

  .article-info {
    background-color: transparent;
  }

  .slider-main .slick-track .slick-slide {
    visibility: hidden;
  }

  .slider-main .slick-track .slick-active {
    visibility: visible;
    width: 100% !important;
  }

  .container-slick {
    display: none;
  }

  .slider-main-image {
    margin: 0 auto 40px;
    display: inherit;
  }

  .article-info,
  .fahrzeugsuche__id,
  .footer-main,
  .footer-main a {
    color: #000 !important;
  }

  .img-slick {
    display: block!important;
  }

  .header-brands {
    opacity: 0;
  }

  .fahrzeugsuche__info {
    margin-bottom: 0;
  }

  .article-info__price,
  .article-info__mwst {
    text-align: right;
  }

  .details-collapse {
    page-break-before: always;
    margin-top: 50px;
  }

  .contact_2cols #request {
    page-break-before: always;
  }

  .footer__contact {
    margin-left: -100%;
  }

  footer {
    background-color: transparent;
    background-image: none;
  }

  .footer-main .col-12 {
    padding-left: 0;
  }
}